.titleStyle {
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.31px;
    color: #333333;
}

.shortInfoStyle {
    /* width: 1302px; */
    height: 131px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.fontBold {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #333333;
}

.currentAdminCount {
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.56px;
    color: #2d5fa7;
    min-height: 70px;
}

.inputStyle {
    width: 223px;
    padding-left: 15px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #f7f7f7;
}

.inputStyle::placeholder {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
}

.submitButton {
    height: 40px;
    border-radius: 5px;
    background-color: #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: none;
}

.borderStyle {
    border-right: solid 1px #e3e3e3;
    border-left: solid 1px #e3e3e3;
}

.adminsStyle {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
}

.tableStyle {
    height: 320px;
    overflow-y: scroll;
}

.tableContainerStyle {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 2px 10px #85CEAE;
}

.ScrollStyle {
    overflow-y: scroll;
    height: 300px;
}

.ScrollStyle::-webkit-scrollbar {
    width: 8px;
    background: #f1f1f1;
    border-radius: 5px;
  }
  
  .ScrollStyle::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 5px;
  }

.rowBg {
    background-color: #F7F7F7;
}

.customRowBg {
    background-color: #ffffff;
}

.removeAdmin {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #d87777;
}

.inviteUserBox {
    /* width: 1266px;
    height: 77px; */
    border-radius: 5px;
    border: solid 1px #e3e3e3;
}

.cursorStyle:hover {
    cursor: pointer;
}

.containerStyle {
    /* height: 43.96vh; */
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.textStyle {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
}