th {
  padding-left: 1.5%;
  padding-bottom: 0.6%;
}

tbody {
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
}

td {
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1.5%;
  height: 18.9px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
  //background-color: #fff;
  color: #333;
}

tr:nth-child(odd) {  
  background-color: #f7f7f7;
}

.urlWrap {
  max-width: 700px;
}

.rowDetail {
  color: #2d5fa7;
  width: 30px;
  cursor: pointer;
}