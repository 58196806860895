.navbar {
  height: 8.5vh;
  min-height: 30px;
  max-height: 50px;
  background-color: #ffffff;
  border-top: 1px solid #e3e3e3;
  padding-left: 2.4%;
}

.subNavbar {
  height: 8.5vh;
  min-height: 40px;
  background-color: #ffffff;
  padding-left: 2.5%;
}

.navbarFontStyle {
  font-family: 'Roboto';
  height: 5.55vh;
  min-height: 20px;
  max-height: 30px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.19px;
  color: #333333;
  border-top: 4px solid #ffffff;
  padding: 10px 0 0 0;
  margin-left: 43px;
}

.navbarSelectedItemStyle {
  font-weight: bold;
  border-top: 4px solid #6eb0e1;
}

.navbarSelectedItemStyle:hover {
  cursor: pointer;
}

.navbarFontStyle:hover {
  cursor: pointer;
}
