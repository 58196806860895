.filter-popover-wrapper {
  position: relative;
  align-items: center;
  cursor: pointer;
}

.filter-popover-wrapper span:hover {
  color: #2d5fa7;
}

.filter-popover-content {
  min-width: 15vw;
  top: 30px;
  opacity: 0;
  visibility: hidden;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  transform: translate(0, 10px);
  background-color: #FFFFFF;
  padding: 1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.filter-arrow {
  right: 93px !important;
}

// .filter-popover-content:before {
//   position: absolute;
//   z-index: -1;
//   content: '';
//   left: 235px;
//   top: -8px;
//   border-style: solid;
//   border-width: 0 10px 10px 10px;
//   border-color: transparent transparent #FFFFFF transparent;
//   transition-duration: 0.3s;
//   transition-property: transform;
// }

.filter-popover-content-after {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 5px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.filter-popover-message {
  text-align: center;
}

.filter-popover-item:hover {
  background: #f7f7f7;
}

.filter-popover-item div {
  white-space: nowrap;
}

.filter-popover-item img {
  padding-right: 3px;
}

.bold-text {
  font-family: 'Roboto';
  font-size: '15px';
  font-weight: bold;
  // color: #333333;
}

.filter-popover-remove {
  display: none;
}

.parent:focus {
  outline: 0;
}
