.navbar {
    height: 5.8vh;
    background-color: #FFFFFF;
    border-top: 1px solid #e3e3e3;
}

.navbarFontStyle {
    font-family: 'Roboto';
    height: 5.55vh;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #333333;
    border-top: 4px solid #FFFFFF;
}

.navbarSelectedItemStyle {
    font-family: 'Roboto';
    height: 5.55vh;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #333333;
    border-top: 4px solid #6eb0e1;
}

.navbarSelectedItemStyle:hover {
    cursor: pointer;
}

.navbarFontStyle:hover {
    cursor: pointer;
}