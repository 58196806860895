.profileFontStyle {
  font-family: 'Roboto';
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.31px;
  color: #333333;
}

.containerStyle {
  width: 50%;
  min-width: 650px;
  /* height: 538px; */
  min-height: 550px;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}

.textStyle {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.23px;
  color: #333333;
}

.inputStyle {
  width: 70%;
  height: 55px;
  border-radius: 5px;
  padding-left: 15px;
  border: none;
  background-color: #f7f7f7;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  color: var(--dark-slate-blue);
}

.inputTextGray {
  color: gray;
}

.border {
  height: 1px;
  border-top: solid 1px #e3e3e3;
}

.submitButton {
  width: 159px;
  height: 40px;
  border-radius: 5px;
  background-color: #6eb0e1;
  border: none;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.cancelButton {
  width: 98px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #6eb0e1;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #859dce;
}

.dropdown {
    width: 200px;
    margin: 0 272px;
    user-select: none;
    position: relative;
}

.dropdown .dropdownBtn {
    padding: 15px 20px;
    background: #75a3e5;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin-top: -39px;
}

.dropdown .dropdownContent {
    position: absolute;
    top: -294%;
    left: 0;
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    color: #333;
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.dropdown .dropdownContent .dropdownItem {
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 5px;
}

.dropdown .dropdownContent .dropdownItem:hover {
    background: #75a3e5;
    color: #fff;
}