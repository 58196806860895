.navbar {
    height: 5.8vh;
    background-color: #FFFFFF;
    border-top: 1px solid #e3e3e3;
}

.navbarFontStyle {
    font-family: 'Roboto';
    height: 5.55vh;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #333333;
    border-top: 4px solid #FFFFFF;
}

.textDecoration {
    text-decoration: none !important;
    
}

.navbarSelectedItemStyle {
    font-size: 15px;
    font-weight: bold;
    border-top: 4px solid #6eb0e1;
}

.workArea {
    /* width: 100% !important; */
    margin: 10px 30px 10px 30px !important;
}

.pixmFilterBar {
    height: 57px;
    width: 99.8%;
    margin-left: 1.5px;
    background-color: #ffffff;
    color: #333333;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
    /* border-radius: 5px; */ /*fix select box for this*/
    font-size: 1vw;
    cursor: pointer;
  }

.monthTab {
    padding-left: 18px;
}

.monthYear {
    padding-left: 22px;
}

.navbarFontStyle:hover {
    cursor: pointer;
}

.navbarSelectedItemStyle:hover {
    cursor: pointer;
}

.pageTitle {
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.31px;
    color: #333333;
}

.headerContiner {
    height: 131px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    width: 99.8%;
    margin-left: 0.1%;
}

.activeUsersFontStyle {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #333333;
}

.countStyle {
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.56px;
    color: #2d5fa7;
    /* text-align: center; */
}

.borderStyle {
    border-left: solid 1px #e3e3e3;
}

.updateButton {
    height: 40px;
    border-radius: 5px;
    background-color: #6eb0e1;
    border: none;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.userLicenses {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
}

.filterBackground {
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.rightBorder {
    border-right: solid 1px #e3e3e3;
}

.tableStyle {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 2px 10px #85CEAE;
}

.rowBg {
    background-color: #F7F7F7;
}

.customRowBg {
    background-color: #ffffff;
}

.pointer {
    width: 30px;
}

.pointer:hover {
    cursor: pointer;
}

.activeFont {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #85ceae;
}

.descriptionStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: #333333;
}

.licenseKeyContainer {
    height: 146px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.licenseKeyFontStyle {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
}

.licenseKeyInputStyle {
    width: 40%;
    height: 55px;
    border-radius: 5px;
    background-color: #f7f7f7;
    border: none;
    padding-left: 15px;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
}

.subInfoContainerStyle {
    height: 244px;
    width: 49.5%;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.manualDeploymentContainerStyle {
    display: flex;
    width: 60%;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.detailsGuideTitle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: #333333;
}

.detailsGuideStyle {
    height: 55px;
    border-radius: 5px;
    background-color: #f7f7f7;
}

.detailsGuideFontStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
}

.downloadButton {
    border: none;
    border-radius: 5px;
    background-color: #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff !important;
    text-decoration: none !important;
}

.disabledDownloadButton {
    border: none;
    border-radius: 5px;
    border-color: #cccccc;
    background-color: #e3e3e3;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #333333 !important;
    text-decoration: none !important;
}

.ScrollStyle {
    overflow-y: scroll;
    height: 300px;
}

.ScrollStyle::-webkit-scrollbar {
    width: 8px;
    background: #f1f1f1;
    border-radius: 5px;
  }
  
  .ScrollStyle::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 5px;
  }

.protectChrome{
    width: 215px;
    color: white;
    padding: 11px 18px;
    border-radius: 5px;
    background-image: linear-gradient(to bottom, rgba(45, 95, 167, 0.25), rgba(45, 95, 167, 0.25)), linear-gradient(102deg, #6eb0e1 8%, #a8b3dd 104%);
}

.addToChrome{
    border-radius: 5px;
    background-color: #1c73e8;
    padding: 10px 13px;
    color: white;
    width: 160px;
    text-align: center;
}

.stepImg{
    background: #f7f7f7;
    display: flex;
    justify-content: center;
}

.whiteBG{
    background: #ffffff;
}

.nameWrap {
    /* max-width: 300px; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.txtAlignRight {
    text-align: right;
}

.boldFont {
    font-weight: bold;
}

.italicFont {
    font-style: italic;
}