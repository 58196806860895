.headerBg {
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.detailsContainer {
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.inputStyle {
    width: 382px;
    height: 55px;
    border-radius: 5px;
    padding-left: 15px;
    border: none;
    background-color: #f7f7f7;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
}

.inputStyle::placeholder {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: var(--dark-slate-blue);
}

.submitButton {
    height: 40px;
    border-radius: 5px;
    background-color: #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: none;
}

.cancelButton {
    height: 40px;
    border-radius: 5px;
    border: 2px solid #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #859dce;
}

.note {
    color: rgb(180, 180, 180);
    font-size: 0.7em;
    font-style: italic;
}