.loader-wrapper {
  &.isFlex {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &.hasData {
      align-items: flex-start;
    }
  }
  &.isChart:not(.hasData) {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .loader-wrapper-placeholder p {
      font-size: 30px;
      line-height: 38px;
      color: #565656;
      font-weight: 700;
      margin: 0;
      text-align: center;
    }
  }
}

.loader-wrapper-placeholder {
  p {
    font-weight: bold;
    font-family : Ubuntu, sans-serif;
    font-size : 15px;
    text-align: center;
  }
}