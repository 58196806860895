.grid-container {
  min-height: 680px;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}

.grid-card {
  // height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.grid-item {
  //margin: 1%;
  max-width: 621px;
  max-height: 398px;
  // height: auto !important;
  // min-height: 85%;
  border-radius: 5px;
  border: solid 1px #e3e3e3;
  padding: 19px;
}

.verify-status {
  font-family: Roboto;
  font-size: 15px;
  //font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
}

.verified {
  color: #85ceae;
}
.unverified {
  color: #859dce;
}

.grid-text {
  font-family: Roboto;
  font-size: 1vw;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
  color: #333333;
}

.image-container {
  max-width: 583px;
  max-height: 256px;
  border-radius: 5px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}

.grid-card::-webkit-scrollbar {
  width: 8px;
  background: #ffffff;
  border-radius: 5px;
}

.grid-card::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 5px;
}

.grid-body {
  height: 630px;
  overflow-y: scroll;
}

.grid-body::-webkit-scrollbar {
  width: 8px;
  background: #f1f1f1;
  border-radius: 5px;
}

.grid-body::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 5px;
}

.grid-count {
  margin-left: 2vw;
  margin-bottom: 1vh;
  font-size: 1vw;
  font-weight: bold;
  font-stretch: normal;
}

// @media only screen and (max-width: 2550px) {
//   .grid-item {
//     width: 31% !important;
//   }
// }

// @media only screen and (max-width: 1900px) {
//   .grid-item {
//     width: 48% !important;
//   }
// }

// @media only screen and (max-width: 1300px) {
//   .grid-item {
//     width: 100% !important;
//   }
// }

// @media only screen and (max-width: 600px) {
//   .grid-item {
//     width: 100%;
//   }
// }
