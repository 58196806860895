.headerBg {
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.detailsContainer {
    min-height: 550px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.bgColor {
    height: 54px;
    background-color: #f7f7f7;
}

.bgWhite {
    height: 54px;
}

.activeFont {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #85ceae;
}

.ScrollStyle {
    overflow-y: scroll;
    /* height: 400px; */
}

.ScrollStyle::-webkit-scrollbar {
    width: 8px;
    background: #f1f1f1;
    border-radius: 5px;
  }
  
.ScrollStyle::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 5px;
  }

.leftTable {
    min-width: 300px;
}

.rightTable {
    min-width: 850px;
}

.divider {
    min-width: 20px;
}