.accountFont {
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.31px;
    color: #333333;
}

.containerStyle {
    /* height: 43.96vh; */
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.textStyle {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
}

.inputStyle {
    width: 45.9%;
    height: 55px;
    border-radius: 5px;
    border: none;
    padding-left: 15px;
    background-color: #f7f7f7;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
}

input[type="file"] {
    display: none;
}

.whiteLabelInputStyle {
    width: 100%;
}

.whiteLabelFileInputStyle {
    height: 83px;
    justify-content: flex-end;
    align-items: end;
    p {
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 8px;
    }
    label {
        width: 140px;
        height: 55px;
        line-height: 55px;
        margin-bottom: 0;
        border-radius: 5px;
        background-color: #6eb0e1;
        border: none;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        letter-spacing: normal;
        color: #ffffff;
        cursor: pointer;
    }
}

.whitelabelLogoStyle {
    max-width: 100%;
    max-height: 220px;
    object-fit: scale-down;
}

.checkBoxArea {
    flex-direction: row;
}

.shortInputStyle {
    width: 32.5%;
    height: 55px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border: none;
    padding-left: 15px;
    background-color: #f7f7f7;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
}

.extendedInput {
    width: 13.3%;
    height: 55px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #e3e3e3;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.16px;
    color: #333333;
}

.label {
    display: block;
}

.border {
    height: 1px;
    border: solid 1px #e3e3e3;
  }

  .submitButton {
    width: 159px;
    height: 40px;
    border-radius: 5px;
    background-color: #6eb0e1;
    border: none;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.cancelButton {
    width: 98px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #859dce;
}

.boldTextStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    letter-spacing: 0.15px;
    color: #333333;
}

.normalTextStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    letter-spacing: 0.15px;
    color: #333333;
}

.emailTextStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    letter-spacing: 0.15px;
    color: #859dce;
}

.cancelAccountButton {
    width: 158px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #859dce;
}