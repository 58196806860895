// .popover-wrapper {
//   position: relative;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
// }

// .popover-wrapper span:hover {
//   color: #2d5fa7;
// }

// .popover-content {
//   min-width: 10vw;
//   top: 30px;
//   opacity: 0;
//   visibility: hidden;
//   cursor: pointer;
//   position: absolute;
//   transform: translate(0, 10px);
//   background-color: #f7f7f7;
//   padding: 0.5rem;
//   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
// }

// .popover-content:before {
//   position: absolute;
//   z-index: -1;
//   content: '';
//   left: 20px;
//   top: -8px;
//   border-style: solid;
//   border-width: 0 10px 10px 10px;
//   border-color: transparent transparent #f7f7f7 transparent;
//   transition-duration: 0.3s;
//   transition-property: transform;
// }

// .popover-content-after {
//   z-index: 10;
//   opacity: 1;
//   visibility: visible;
//   transform: translate(0, 5px);
//   transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
// }

// .popover-message {
//   text-align: center;
// }

// .popover-item:hover {
//   background: #ececec;
// }

// .popover-item div {
//   white-space: nowrap;
// }

// .popover-item img {
//   padding-right: 3px;
// }

// .popoverFalsePositive {
//   text-decoration: underline;
//   color: blue;
//   cursor: pointer;
// }

// ._deleteBtn{
//   color: red;
//   height: 20px;
//   width: 20px;
//   cursor: pointer;
// }
.stausText {
  color: #333333;
  cursor: pointer;
}

.stausText:hover {
  color: #2d5fa7;
}

.actionContainer {
  width: 180px;
  margin: 65px 82px 47px 246px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #d8d8d8;
  background-color: #fff;
  padding-top: 5% !important;
  padding-bottom: 0% !important;
}

.long {
  height: 98px !important;
}

.short {
  height: 45px !important;
}

.menuItem {
  padding: 19px 0 7.3px;
  font-size: 15px;
}

.actionImg {
  height: 19px;
  padding-right: 2px;
  margin-top: -3.5px;
}
