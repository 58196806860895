.page-navigation {
  width: 40px;
  align-self: center;
  border-radius: 5px;
  background-color: inherit;
  display: block;
  text-align: center;
  cursor: pointer;
  border: none;
}

.page-nav-active {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #e3e3e3;
}

.page-no {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.page-no > div {
  width: 26px;
  height: 26px;
  text-align: center;
  vertical-align: middle;
  line-height: 26px;
  padding: 0 !important;
  border-radius: 5px;
  border: solid 1px #2d5fa7;
  color: #2d5fa7 !important;
  text-overflow: overflow;
}

.current-page-no {
  width: auto;
  min-width: 26px;
  height: 26px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  line-height: 26px;
  border-radius: 5px;
  border: solid 1px #2d5fa7;
  color: #2d5fa7 !important;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  align-self: center;
}
