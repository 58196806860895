.select-box {
  display: flex;
  flex-direction: column;
  height: 55px;
  font-size: 0.85vw;
}

.select-box .options-container {
  background: #ffffff;
  /* color: #333333; */
  max-height: 0;
  width: 20%;
  opacity: 0;
  transition: all 0.4s;
  overflow: hidden;

  order: 1;
}

.selected {
  height: 57px;
  background: #ffffff;
  /* color: #333333; */
  position: relative;

  order: 0;
}

.select-box .options-container.active {
  border: 1px solid #e3e3e3;
  max-height: 240px;
  position: absolute;
  z-index: 1;
  margin-top: 60px;
  opacity: 1;
  overflow-y: scroll;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.26);
}

.toggle-chev {
  transform: rotateX(180deg);
}

.select-box .options-container::-webkit-scrollbar {
  width: 8px;
  background: #ffffff;
  border-radius: 5px;
}

.select-box .options-container::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 5px;
}

.select-box .option,
.selected {
  padding: 1vw 1.2vw;
  cursor: pointer;
}

.select-box .option {
  //font-family: Arial, Helvetica, sans-serif;
}

.select-box .option:hover {
  background: #859dce;
  color: #ffffff;
}

.select-box label {
  cursor: pointer;
}

.select-box .option .checkbox {
  display: none;
}

.search-box {
  width: 100% !important;
  border: none;
  border-radius: 5px;
  height: 40px;
  padding: 9px 8px 10px 13px;
}

.search-box:focus {
  border: none;
  outline: none;
  background: white;
  border-radius: 5px;
}

.search-box-outline {
  margin: 6px 6px 6px 7px;
  border-radius: 5px;
  border: solid 1px #2d5fa7;
}

.search-box-magnifier {
  left: 10px;
  width: 18px;
  height: 22px;
  margin: 2px 0 0 9px;
}

.search-box-close {
  // position: absolute;
  // right: 1px;
  width: 22px;
  margin: 2px 9px 0 0;
}

.icon {
  height: 25px;
}

.selectedInitText{
  width: 87px;
  height: 19px;
  margin: 1px 53px 0 6px;
  //font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
  color: #838282;

}
.initText {
  width: 87px;
  height: 19px;
  margin: 1px 53px 0 6px;
  //font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
  color: #333;
}
