.titleStyle {
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.31px;
    line-height: 0.76;
    letter-spacing: 0.31px;
    color: #333;
}

/* .shortInfoStyle {
    /* width: 1302px; 
    height: 131px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
} */

.fontBold {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #333333;
}

/* .currentAdminCount {
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.56px;
    color: #2d5fa7;
} */

/* .inputStyle {
    width: 223px;
    padding-left: 15px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #f7f7f7;
} */

/* .inputStyle::placeholder {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
} */

.submitButton {
    height: 40px;
    border-radius: 5px;
    background-color: #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: none;
}

/* .adminsStyle {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
} */

/* .tableStyle {
    height: 320px;
    overflow-y: scroll;
} */
/* 
.tableContainerStyle {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 2px 10px #85CEAE;
} */

/* .ScrollStyle {
    overflow-y: scroll;
    height: 300px;
}

.ScrollStyle::-webkit-scrollbar {
    width: 8px;
    background: #f1f1f1;
    border-radius: 5px;
  }
  
  .ScrollStyle::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 5px;
  } */
/* 
.rowBg {
    background-color: #F7F7F7;
}

.customRowBg {
    background-color: #ffffff;
}

.removeAdmin {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #d87777;
}

.inviteUserBox {
    border-radius: 5px;
    border: solid 1px #e3e3e3;
}

.cursorStyle:hover {
    cursor: pointer;
} */

.containerStyle {
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.subTitleTextStyle {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
    margin-bottom: 19px;
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.navbar {
    height: 5.8vh;
    background-color: #FFFFFF;
    border-top: 1px solid #e3e3e3;
}

.navbarFontStyle {
    font-family: 'Roboto';
    height: 5.55vh;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #333333;
    border-top: 4px solid #FFFFFF;
}


/* .textDecoration {
    text-decoration: none !important;
    
} */

.navbarSelectedItemStyle {
    font-family: 'Roboto';
    height: 5.55vh;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #333333;
    border-top: 4px solid #6eb0e1;
}

.navbarFontStyle:hover {
    cursor: pointer;
}

.navbarSelectedItemStyle:hover {
    cursor: pointer;
}

.pageTitle {
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.31px;
    color: #333333;
}

.headContainer {
    height: 100px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}
/* 
.activeUsersFontStyle {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #333333;
} */

/* .countStyle {
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.56px;
    color: #2d5fa7;
} */

/* .borderStyle {
    border-left: solid 1px #e3e3e3;
}

.updateButton {
    height: 40px;
    border-radius: 5px;
    background-color: #6eb0e1;
    border: none;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
} */
/* 
.userLicenses {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
}

.filterBackground {
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
} */

/* .rightBorder {
    border-right: solid 1px #e3e3e3;
}

.pointer {
    width: 30px;
} */

.cursorPointer{
    cursor: pointer;
    color: #6eb0e1;
    width: 150px;
}

.cursorPointer:hover {
    cursor: pointer;
}

/* .activeFont {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.19px;
    color: #85ceae;
}

.descriptionStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: #333333;
} */

/* .licenseKeyContainer {
    height: 146px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.licenseKeyFontStyle {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
} */

/* .licenseKeyInputStyle {
    width: 40%;
    height: 55px;
    border-radius: 5px;
    background-color: #f7f7f7;
    border: none;
    padding-left: 15px;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
} */

.subInfoContainerStyle {
    width: 49.5%;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

/* .manualDeploymentContainerStyle {
    display: flex;
    width: 60%;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
} */

/* .detailsGuideStyle {
    height: 55px;
    border-radius: 5px;
    background-color: #f7f7f7;
}

.detailsGuideFontStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
} */

/* .downloadButton {
    border: none;
    border-radius: 5px;
    background-color: #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff !important;
    text-decoration: none !important;
} */

/* .protectChrome{
    width: 215px;
    color: white;
    padding: 11px 18px;
    border-radius: 5px;
    background-image: linear-gradient(to bottom, rgba(45, 95, 167, 0.25), rgba(45, 95, 167, 0.25)), linear-gradient(102deg, #6eb0e1 8%, #a8b3dd 104%);
}

.addToChrome{
    border-radius: 5px;
    background-color: #1c73e8;
    padding: 10px 13px;
    color: white;
    width: 160px;
    text-align: center;
}

.stepImg{
    background: #f7f7f7;
    display: flex;
    justify-content: center;
}

.whiteBG{
    background: #ffffff;
} */

.textStyle {
font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.15px;
  color: #333;
}

.greyBoxTextStyle{
font-family: Roboto;
font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.15px;
  color: var(--dark-slate-blue);
  background-color: #f7f7f7; 
  cursor: pointer;
  border-radius: 5px;
}

.formatSection {
  background-color: #f7f7f7;
  padding: 5px 9px 4px 10px;
  margin-bottom: 14px;
}

.formatSectionParameter{
  /* width: 145px; */
  min-width: 10vw;
  padding: 8px;
}

.formatSectionDescription{
  color: black;
  /* width: 300px; */
  min-width: 20vw;
  padding: 8px;
  
}

.formatSectionUse{
    color: black;
    padding: 8px;
    min-width: 15vw;
}

.subHeaderFontStyle {
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.15px;
  color: '#333';
}

.verticalLine{
    border-left: thin solid lightgrey;
}

.boxShadow{
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
}