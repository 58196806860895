.wrapper {
  width: 99.8%;
  margin-left: 0.1%;
  margin-top: 1.5%;
}

.sections {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14.5px 38.5px 18px 39.5px;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.actionBar {
  height: 55px;
}

.pointer {
  color: #333333;
  cursor: pointer;
}

.pointer:hover {
  color: #2d5fa7;
}

.details {
  min-height: 607px;
  max-height: 1500px;
  margin-top: 1.5%;
  padding: 18px;
  overflow-x: auto;
}

.tableView {
  width: 23.5vw;
  min-width: 330px;
}

.tabView {
  width: 66.5vw;
  min-width: 964px;
}

#detailsTable {
  width: 100% !important;
}

#detailsTable td:first-child {
  font-weight: bold;
  width: 34%;
  min-height: 54px;
  vertical-align: top;
  padding-top: 5%;
  padding-left: 26px;
  padding-bottom: 5%;
}

#detailsTable td {
  min-height: 54px;
  padding: 3%;
  max-width: 200px;
  word-wrap: break-word;
}

.tabs {
  display: flex;
  width: 91%;
  min-height: 45px;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
}

.tab {
  width: 33.33%;
  text-align: center;
  padding-top: 10px;
}

.rightLine {
  border-right: 1px solid #e3e3e3;
  min-height: 45px;
}

.dataView {
  margin-top: 2%;
  max-width: 1067px;
  max-height: 560px;
}

.dataImg {
  border-radius: 5px;
  max-width: 66.5vw;
  min-width: 880px;
  min-height: 600px;
}

.imageContainer {
  width: 100%;
}

.mobileImg {
  height: 450px;
  padding-left: 40%;
}

.divider {
  min-width: 20px;
}
