.profile-popover {
  z-index: 1;
  position: absolute;
  width: 200px;
  background-color: #fff;
  border-radius: 4px;
  top: 70px;
  right: 27px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.26);
}

.profile-popover-remove {
  display: none;
}

.profile-option {
  display: flex;
  flex-direction: row;
}

.profile-option:hover {
  background: #f7f7f7;
}

.parent:focus {
  outline: 0;
}

.option-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}