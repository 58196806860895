  .box {
    position: fixed;
    width: 600px;
    min-height: 325px;
    top: 28%;
    left: 30%;
    background: white;
    border-radius: 5px;
    padding: 20px;
    overflow: auto;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.26);
    z-index: 10;
  }
