.headerBg {
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.accountFont {
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.31px;
    color: #333333;
}

.containerStyle {
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.textStyle {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.23px;
    color: #333333;
}

.inputStyle {
    width: 382px;
    height: 55px;
    border-radius: 5px;
    border: none;
    padding-left: 15px;
    background-color: #f7f7f7;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
}

.shortInputStyle {
    width: 272px;
    height: 55px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border: none;
    padding-left: 15px;
    background-color: #f7f7f7;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
}

.extendedInput {
    width: 110px;
    height: 55px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #e3e3e3;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.16px;
    color: #333333;
}

.border {
    margin-top: 77px;
    border: solid 1px #e3e3e3;
  }

  .submitButton {
    width: 159px;
    height: 40px;
    border-radius: 5px;
    background-color: #6eb0e1;
    border: none;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.cancelButton {
    width: 98px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #859dce;
}

.boldTextStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    letter-spacing: 0.15px;
    color: #333333;
}

.normalTextStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    letter-spacing: 0.15px;
    color: #333333;
}

.emailTextStyle {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    letter-spacing: 0.15px;
    color: #859dce;
}

.cancelAccountButton {
    width: 158px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #859dce;
}

.note {
    color: rgb(180, 180, 180);
    font-size: 0.7em;
    font-style: italic;
}