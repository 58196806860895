._modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
}

._modal-body {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: white;
  padding: 14px 28px;
  border: 0.5px solid gray;
  border-radius: 5px;
  width: 466px;
  height: 223px;
  float: left;
  display: block;
  text-align: left;
}

._modal-header {
  height: 60px;
  width: 465px;
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.info {
  background: #3598db;
}

.warning {
  background: #f39c11;
}

.success {
  background: #00bc8c;
}

.error {
  background: #e84c3d;
}

._modal-icon {
  display: table-cell;
  vertical-align: middle;
  height: 59px;
  width: 66px;
  border-top-left-radius: 5px;
}

._modal-title {
  font-size: 20px;
  color: white;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
}

._modal-content {
  position: absolute;
  top: 33%;
  left: 3%;
  width: 444px;
  height: 162px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

._modal-buttons {
  display: flex;
  justify-content: right;
  position: absolute;
  bottom: 38px;
  right: 27px;
  width: 290px;
}

._modal-button {
  width: 89px;
  border-radius: 5px;
  height: 35px;
  margin-left: 20px;
}

._modal-image {
  padding: 10px;
  height: 55px;
  width: 55px;
  position: absolute;
  top: 1px;
  left: 6px;
}

._modal-button:hover {
  background-color: lightgray;
}

._modal-icons {
  color: white;
  padding: 10px;
  height: 55px;
  width: 55px;
  position: absolute;
  top: 1px;
  left: 6px;
}