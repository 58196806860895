 .innerBox{
    background: white;
    color: #565656;
    font-weight: bold;
    font-size: 40px;
    font-family: Ubuntu, sans-serif;
    line-height: 1.0;
  }

  .headerDiv{
      margin-top: 0%;
  }
  
  .orgDiv{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-size: 16px;
    font-family: Ubuntu, sans-serif;
    line-height: 1.2;
    font-weight: normal;
  }

  .menuDiv{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-size: 16px;
    font-family: Ubuntu, sans-serif;
    line-height: 1.2;
    font-weight: normal;
  }
  
  .MiniDash{
    position: absolute;
    right:0px;
    bottom:0px;
    height: 15%;
  }
  
  .buttonOk{
    border: none;
    color: black;
    padding: 10px 60px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    background-image: linear-gradient(to bottom, rgba(45, 95, 167, 0.25), rgba(45, 95, 167, 0.25)), linear-gradient(98deg, #6eb0e1 7%, #a8b3dd 103%);
    border-radius: 5px;
  }
  
  .buttonDiv{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
