.chartContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: 2.6%;
  margin-right: 31px;
  padding: 1px;
  overflow-x: auto;
}

.chartRow {
  display: flex;
  width: 100%;
}

.rightChart {
  margin-left: 10px;
}

.outerBox {
  margin-bottom: 21px;
}

.innerBox {
  min-height: 389px;
  width: 430px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  margin-top: 18px;
}

.incidentInnerBox {
  min-height: 324px !important;
}

.headDetails {
  width: 244px;
  height: 19px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: 0.23px;
  color: #333;
}

.legendPosition {
  display: flex;
  margin-top: 5px;
  margin-left: 24px;
}

.symbol {
  height: 17px;
  width: 17px;
  margin-left: 24px;
  margin-right: 7px;
  border-radius: 20px;
}

.verified {
  background: #df1b1b;
}

.suspicious {
  background: #f2d893;
}

.legendHead {
  width: 34px;
  height: 19px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
  color: #333;
}

.legendText {
  width: 95px;
  height: 19px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
  color: #333;
}

.notFound {
  width: 100%;
  text-align: center;
  padding-top: 140px;
  font-weight: 700 !important;
  font-style: normal;
  font-family: Ubuntu, sans-serif;
  font-size: 30px;
  line-height: 38px;
  color: #565656;
}
